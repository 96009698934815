import { CompanyAttachment } from '@/entities/system-global.entity';
import { Mutations } from '../mutations.enum';

export default {
  [Mutations.SET_COMPANY](store: any, company: string) {
    store.company = company;
  },
  [Mutations.SET_COMPANY_DATA](store: any, company_attachment: CompanyAttachment) {
    store.company.company_attachment = company_attachment;
  },
};
