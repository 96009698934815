const ChecklistsForm = () => import('./components/form/checklist-form.component.vue');
const ChecklistsView = () => import('./Checklists.vue');
const ChecklistsList = () => import('./components/list/checklist-list.component.vue');

export default {
  path: 'checklists',
  component: ChecklistsView,
  meta: { requiresAuth: true },
  children: [
    {
      path: '/',
      name: 'checklist-list',
      component: ChecklistsList,
      meta: {
        roles: ['checklist.list'],
        requiresAuth: true,
        breadcrumbs: [{ text: 'Checklists cadastrados', href: '' }],
      },
    },
    {
      path: 'cadastro',
      name: 'checklist-create',
      component: ChecklistsForm,
      meta: {
        roles: ['checklist.create'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Checklists cadastrados', href: 'checklist-list' },
          { text: 'Cadastrar Checklists', href: '' },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'checklist-edit',
      component: ChecklistsForm,
      meta: {
        roles: ['checklist.edit'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Checklist cadastrados', href: 'checklist-list' },
          { text: 'Editar checklist', href: '' },
        ],
      },
    },
  ],
};
