export enum NotificationIconsEnum {
  Reminder = 'event',
  Comments = 'contact_mail',
  Tasks = 'contact_mail',
}

export enum NotificationTitleEnum {
  Reminder = 'Você possui um lembrete',
  Comments = 'Você foi mencionado em um comentário',
  Tasks = 'Você foi mencionado em uma tarefa',
}

export enum NotificationRedirectEnum {
  Reminder = 'client-edit',
  Comments = 'client-edit',
  Tasks = 'client-edit',
}

export enum NotificationTypeEnum {
  Comments = 'comments',
  Reminder = 'reminder',
  Tasks = 'tasks',
}
