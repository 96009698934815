
import { Component, Vue, Prop } from 'vue-property-decorator';
import rulesService from '@/services/rules.service';
import DatePicker from '@/components/date-picker/date-picker.component.vue';

import { AddressViaCepInterface } from '@/components/address/interfaces/address-via-cep.interface';
import { Address } from '@/components/address/entities/address.entity';
import AddressComponent from '@/components/address/address.component.vue';
import AlertError from '@/components/alert-error/alert-error.component.vue';
import { Unit } from '../entities/unit.entity';
import unitsService from '../services/units.service';
import { CommercialStocksEnum } from '../enums/commercial-stocks.enum';

@Component({
  components: {
    AddressComponent,
    AlertError,
    DatePicker,
  },
})
export default class UnitFormComponent extends Vue {
  public $refs: any;

  @Prop({
    type: Object,
    default: () => new Unit(),
  })
  private unit!: Unit;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  private dialog!: boolean;

  private unitTypes: any[] = [];

  private subgroups: any[] = [];

  private commercialStocks: any[] = [];

  private moneyOptionsMask = {
    locale: 'pt-BR',
    prefix: 'R$',
    suffix: '',
    length: 9,
    precision: 2,
  };

  private rules: any = {
    required: rulesService.required,
    email: rulesService.email,
  };

  private status: any[] = [
    {
      id: 0,
      name: 'Inativo',
    },
    {
      id: 1,
      name: 'Ativo',
    },
  ];

  private validate() {
    return this.$refs.form.validate();
  }

  private async setAddressByCep(address: AddressViaCepInterface) {
    const fullAddress = { ...address, number: this.unit.address.number };
    this.unit.address = new Address(fullAddress);
  }

  private setUnitType(item: string) {
    this.unit.unit_type_id = item;
  }

  private async getUnitTypes() {
    this.unitTypes = await unitsService.getUnitTypes({
      params: {
        company_id: this.unit.company_id,
      },
    });
  }

  private async getSubGroups() {
    this.subgroups = await unitsService.getSubGroups({
      params: {
        company_id: this.unit.company_id,
      },
    });
  }

  private async getSubGroupsById(id: string) {
    this.subgroups = await unitsService.getSubGroupsById(id);
  }

  private async getCommercialStocks() {
    this.commercialStocks = await unitsService.getCommercialStocks();
    if (!this.unit.commercial_stock) {
      this.unit.commercial_stock = CommercialStocksEnum.Disponivel;
    }
  }

  private getData() {
    const promises = [this.getUnitTypes(), this.getSubGroups(), this.getCommercialStocks()];
    return Promise.all(promises);
  }

  private async created() {
    this.$loader.open();
    await this.getData();
    this.$loader.close();
  }
}
