import { AxiosResponse } from 'axios';
import http from '@/services/http.service';
import attachmentService from '@/modules/shared/services/attachment.service';
import {
  ISimplePaginationService,
  SimplePagination,
} from '@/entities/pagination/simple-pagination';
import { Company } from '../entities/company.entity';

class CompaniesService {
  public simplePaginationService: ISimplePaginationService;

  constructor(simplePaginationService: ISimplePaginationService) {
    this.simplePaginationService = simplePaginationService;
  }

  public create(payload: Company) {
    return http.post('/company', payload).then(({ data }: AxiosResponse) => data);
  }

  public update(payload: Company) {
    return http.put(`/company/${payload.id}`, payload).then(({ data }: AxiosResponse) => data);
  }

  public delete(companyId: string) {
    return http.delete(`/company/${companyId}`).then(({ data }: AxiosResponse) => data);
  }

  public getAllCompanies() {
    return http.get('/company').then(({ data }: AxiosResponse) => data.data);
  }

  public getAllCompaniesPaginated() {
    return http
      .get('/company', {
        params: {
          paginated: 1,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }

  public getCompanyById(id: string) {
    return http.get(`/company/${id}`).then(({ data }: AxiosResponse) => data.data);
  }

  public setLogo(id: string, logoFormData: FormData) {
    return http
      .post(`/company/${id}/attachment/logo`, logoFormData)
      .then(({ data }: AxiosResponse) => data.data);
  }

  public setBackground(id: string, logoFormData: FormData) {
    return http
      .post(`/company/${id}/attachment/background`, logoFormData)
      .then(({ data }: AxiosResponse) => data.data);
  }

  public removeAttachmentById(idFile: string) {
    return attachmentService.removeAttachmentById(idFile);
  }
}
const simplePaginationService = new SimplePagination();
export default new CompaniesService(simplePaginationService);
