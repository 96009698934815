
import {
  Component, Vue, Prop, Emit,
} from 'vue-property-decorator';
import moment from '@/services/moment/moment.service';

@Component
export default class DatePickerComponent extends Vue {
  @Prop({ type: Boolean, default: () => false })
  private readonly outlined!: boolean;

  @Prop({ type: Boolean, default: () => false })
  private readonly dense!: boolean;

  @Prop({ type: Boolean, default: () => false })
  private readonly disabled!: boolean;

  @Prop({ type: Boolean, default: () => true })
  private readonly clearable!: boolean;

  @Prop({ type: String, default: () => '' })
  private readonly value!: string;

  @Prop({ type: String, default: () => '' })
  private readonly label!: string;

  @Prop({ type: Function })
  private readonly allowedDates!: any;

  @Prop({ type: Array, default: () => [] })
  private readonly rules!: string;

  @Prop({ type: Boolean, default: () => true })
  private readonly attach!: boolean;

  private openDatePicker: boolean = false;

  private get dateFormatted() {
    if (this.value) {
      return moment(this.value).format('DD/MM/YYYY');
    }
    return '';
  }

  private setDate(date: any) {
    this.$emit('input', date);
    this.openDatePicker = false;
  }

  private inputChanged(date: string = '') {
    if (date === null) {
      this.setDate(undefined);
      return;
    }
    if (date && date.length === 10 && moment(date).isValid()) {
      const dateFormatted = date
        .split('/')
        .reverse()
        .join('-');
      this.setDate(dateFormatted);
    }
  }

  private openPicker() {
    this.openDatePicker = true;
  }
}
