
import { mapActions } from 'vuex';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Company } from '@/modules/Administrative/modules/Companies/entities/company.entity';
import companiesService from '@/modules/Administrative/modules/Companies/services/companies.service';

@Component({
  methods: {
    ...mapActions(['setCompany']),
  },
})
export default class AdminSelectCompanyDialog extends Vue {
  @Prop({
    type: Boolean,
    default: () => false,
  })
  public readonly value!: boolean;

  public readonly setCompany!: any;

  public company: any = new Company();

  public companies: Company[] = [];

  public close() {
    this.$emit('input', false);
  }

  changeCompanyId() {
    this.setCompany(this.company);
    this.$emit('refresh');
    this.close();
  }

  private async getCompanies() {
    const companies = await companiesService.getAllCompanies();
    this.companies = companies.map((company: Company) => new Company(company));
  }

  created() {
    this.getCompanies();
  }
}
