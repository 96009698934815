import { AxiosResponse } from 'axios';
import http from '@/services/http.service';

class AttachmentService {
  public removeAttachmentById(idFile: string) {
    return http.delete(`/attachment/${idFile}`).then(({ data }: AxiosResponse) => data.data);
  }
}

export default new AttachmentService();
