import store from '@/store';
import userService from '@/modules/Administrative/modules/Users/services/user.service';
import PermissionAccessInstall from './permission-access/permission-access-install';
import { SystemInfo } from '../entities/system-global.entity';

export default class RenderApp {
  static async getRequireInfo() {
    const { token } = store.getters;
    if (token) {
      const data = await userService.getUserInfoByToken(token);
      const systemInfo = new SystemInfo(data);
      await store.dispatch('setUser', systemInfo.user);
      await store.dispatch('setCompanyData', systemInfo.company_attachment);
      await PermissionAccessInstall.installPlugin(systemInfo.user);
    }
  }
}
