
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AlertTypesEnum } from '../../modules/Login/enum/alert-type.enum';

@Component
export default class AlertErrorComponent extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  private error?: any;

  private alertTypes: any = AlertTypesEnum;

  private get allErrors(): Array<any> {
    const errors = Object.keys(this.error);
    if (errors.length) {
      return errors.reduce((acc: Array<any>, value) => {
        acc.push(...this.error[value]);
        return acc;
      }, []);
    }
    return [];
  }
}
