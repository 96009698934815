const ProductInterestForm = () => import('./components/form/product-interest-form.component.vue');
const ProductInterestView = () => import('./ProductInterests.vue');
const ProductInterestList = () => import('./components/list/product-interest-list.component.vue');

export default {
  path: 'produtos-de-interesse',
  component: ProductInterestView,
  meta: { requiresAuth: true },
  children: [
    {
      path: '/',
      name: 'product-interest-list',
      component: ProductInterestList,
      meta: {
        roles: ['product_interest.list'],
        requiresAuth: true,
        breadcrumbs: [{ text: 'Produto de interesse cadastrados', href: '' }],
      },
    },
    {
      path: 'cadastro',
      name: 'product-interest-create',
      component: ProductInterestForm,
      meta: {
        roles: ['product_interest.create'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Produto de interesse cadastrados', href: 'product-interest-list' },
          { text: 'Cadastrar produto de interesse', href: '' },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'product-interest-edit',
      component: ProductInterestForm,
      meta: {
        roles: ['product_interest.edit'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Produto de interesse cadastrados', href: 'product-interest-list' },
          { text: 'Editar produto de interesse', href: '' },
        ],
      },
    },
  ],
};
