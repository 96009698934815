import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { GroupEnum } from '@/modules/Administrative/modules/Users/enum/group.enum';
import ClientsModule from '../modules/Clients/route';
import AdminModule from '../modules/Administrative/route';
import LoginModule from '../modules/Login/route';
import SalesFunnelModule from '../modules/SalesFunnel/route';
import ReportModule from '../modules/Report/route';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../modules/Home/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/acesso-nao-autorizado',
    name: 'not-authorized',
    component: () => import('../components/not-authorized/not-authorized.component.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '*',
    redirect: '/',
  },
  LoginModule,
  ClientsModule,
  AdminModule,
  SalesFunnelModule,
  ReportModule,
];

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
});

function requiresAuth(to: any): boolean {
  return to.matched.some((route: any) => route.meta.requiresAuth);
}

function isSuperAdmin() {
  if (store.getters.user.group) {
    return store.getters.user.group.name === GroupEnum.SUPER_ADMINISTRATOR;
  }
  return false;
}
function authenticatedFlow(next: any, to: any) {
  const rolesRoute = to.meta.roles || [];
  if (isSuperAdmin()) {
    next();
  } else if (!rolesRoute.length) {
    next();
  } else {
    const allRoles = store.getters.user.roles;

    if (allRoles && allRoles.length) {
      const rolesString = allRoles[0].permissions.map((it: any) => it.name);
      const hasAccess = rolesString.some((role: string) => rolesRoute.includes(role));
      if (hasAccess) {
        next();
      } else {
        next({
          name: 'home',
        });
      }
    }
  }
}

router.beforeEach((to: any, from: any, next: any) => {
  const isRequireAuthView: boolean = requiresAuth(to);
  if (isRequireAuthView) {
    const { isAuthenticated } = store.getters;
    if (!isAuthenticated) {
      next({
        path: '/auth',
      });
    } else {
      authenticatedFlow(next, to);
    }
  } else {
    next();
  }
});

export default router;
