import { CompanyAttachment } from '../../entities/system-global.entity';
import { CompanyAttachmentEnum } from '../../modules/Administrative/modules/Companies/components/form/enums/company-attachment-enum.enum';

function getLogo(storeCompany = []) {
  if (!storeCompany.length) return '';

  const logoData: any = storeCompany.find(
    (it: CompanyAttachment) => it.type === CompanyAttachmentEnum.GENERAL,
  );
  return logoData ? logoData.path : '';
}

function getBackground(storeCompany = []) {
  if (!storeCompany.length) return '';

  const logoData: any = storeCompany.find(
    (it: CompanyAttachment) => it.type === CompanyAttachmentEnum.BACKGROUND,
  );
  return logoData ? logoData.path : '';
}

export default {
  logo: (store: any) => getLogo(store.company),
  background: (store: any) => getBackground(store.company),
  company: (store: any) => store.company,
};
