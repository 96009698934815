import { AxiosResponse } from 'axios';
import {
  ISimplePaginationService,
  SimplePagination,
} from '@/entities/pagination/simple-pagination';
import http from '@/services/http.service';
import { SetPasswordInterface } from '../interfaces/reset-password.interface';
import { UserPayloadInterface } from '../interfaces/user-payload.interface';

class UserService {
  public simplePaginationService: ISimplePaginationService;

  constructor(simplePaginationService: ISimplePaginationService) {
    this.simplePaginationService = simplePaginationService;
  }

  public create(payload: UserPayloadInterface) {
    return http.post('/user', payload).then(({ data }: AxiosResponse) => data);
  }

  public update(userId: string, payload: UserPayloadInterface) {
    return http.put(`/user/${userId}`, payload).then(({ data }: AxiosResponse) => data);
  }

  public delete(userId: string) {
    return http.delete(`/user/${userId}`).then(({ data }: AxiosResponse) => data);
  }

  public resetPassword(email: string) {
    return http.get(`/user/reset-password?email=${email}`).then(({ data }: AxiosResponse) => data);
  }

  public setPassword(userId: string, passwordContent: SetPasswordInterface) {
    return http
      .patch(`/user/${userId}/set-password`, passwordContent)
      .then(({ data }: AxiosResponse) => data);
  }

  public getAllUsers(params: any) {
    return http.get('/user/', params).then(({ data }: AxiosResponse) => data.data);
  }

  public getAllUsersPaginate() {
    return http
      .get('/user/', {
        params: {
          paginate: 1,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }

  public getUserById(id: string) {
    return http.get(`/user/${id}`).then(({ data }: AxiosResponse) => data.data);
  }

  public getUserNetworkAvailable(params: any) {
    return http.get('/user/network', params).then(({ data }: AxiosResponse) => data.data);
  }

  public getUserInfoByToken(token: string) {
    return http.get(`auth/user-info/${token}`).then(({ data }: AxiosResponse) => data.data);
  }

  public getSellingManagerUser(params: any) {
    return http.get('/user/selling-managers', params).then(({ data }: AxiosResponse) => data.data);
  }

  public getOrderStatus(params: any = {}) {
    return http.get('/user/order-status', { params }).then(({ data }: AxiosResponse) => data.data);
  }

  public generateSecret(userId: string) {
    return http
      .post(`/user/${userId}/generate-secret`)
      .then(({ data }: AxiosResponse) => data.data);
  }

  public deleteSecret(userId: string) {
    return http.post(`/user/${userId}/delete-secret`).then(({ data }: AxiosResponse) => data.data);
  }
}
const simplePaginationService = new SimplePagination();
export default new UserService(simplePaginationService);
