import { Mutations } from '../mutations.enum';

export default {
  [Mutations.SET_USER](store: any, user: string) {
    store.user = user;
  },
  [Mutations.SET_COMPANY](store: any, company: any) {
    store.user.company = company;
    store.user.company_id = company.id;
  },
};
