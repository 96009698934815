import { User } from '../modules/Administrative/modules/Users/entities/user.entity';
import { CompanyAttachmentEnum } from '../modules/Administrative/modules/Companies/components/form/enums/company-attachment-enum.enum';

export class SystemInfo {
  public user: User;

  public company_attachment: CompanyAttachment[];

  constructor(data: any = {}) {
    this.user = data.user;
    this.company_attachment = data.company_attachment;
  }
}

export interface CompanyAttachment {
  id: string;
  name: string;
  type: CompanyAttachmentEnum;
  path: string;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  file: null;
  pivot: CompanyAttachmentPivot;
}

export interface CompanyAttachmentPivot {
  company_id: string;
  attachment_id: string;
  created_at: string;
  updated_at: string;
}

export interface Company {
  id: string;
  name: string;
  trading_name: string;
}

export interface Group {
  id: string;
  name: string;
}

export interface Role {
  id: string;
  name: string;
  system: boolean;
  pivot: RolePivot;
  permissions: Permission[];
}

export interface Permission {
  id: string;
  name: string;
  guard_name: GuardName;
  module: string;
  active: boolean;
  system: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  pivot: PermissionPivot;
}

export enum GuardName {
  Web = 'web',
}

export interface PermissionPivot {
  role_id: string;
  permission_id: string;
}

export interface RolePivot {
  model_uuid: string;
  role_id: string;
  model_type: string;
}
