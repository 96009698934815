import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import pt from 'vuetify/src/locale/pt';
import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#FF5500',
        visual: '#FF5500',
        default: '#757575',
        info: '#00bcd4',
        success: '#4caf50',
        warning: '#ff9800',
        danger: '#f44336',
        black: '#222',
        base: '#eceff1',
        'primary-font': '#424242',
        'chart-color-font': '#c3c6de',
      },
    },
  },
  icons: {
    iconfont: 'md',
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
});
