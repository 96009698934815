import { LoaderInterface } from '../interfaces/loader.interface';

class LoaderService implements LoaderInterface {
  public show = false;

  public open() {
    this.show = true;
  }

  public close() {
    this.show = false;
  }

  public get isOpen(): boolean {
    return !!this.show;
  }
}

export default new LoaderService();
