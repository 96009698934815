const PaymentConditionForm = () => import('./components/form/payment-condition-form.component.vue');
const PaymentConditionView = () => import('./PaymentConditions.vue');
const PaymentConditionList = () => import('./components/list/payment-condition-list.component.vue');

export default {
  path: 'condicoes-de-pagamento',
  component: PaymentConditionView,
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'payment-condition-list',
      component: PaymentConditionList,
      meta: {
        roles: ['payment_condition.list'],
        requiresAuth: true,
        breadcrumbs: [{ text: 'Condição de Pagamento cadastrado', href: '' }],
      },
    },
    {
      path: 'cadastro',
      name: 'payment-condition-create',
      component: PaymentConditionForm,
      meta: {
        roles: ['payment_condition.create'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Condição de Pagamento cadastrado', href: 'payment-condition-list' },
          { text: 'Cadastrar condição de pagamento', href: '' },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'payment-condition-edit',
      component: PaymentConditionForm,
      meta: {
        roles: ['payment_condition.edit'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Condição de Pagamento cadastrado', href: 'payment-condition-list' },
          { text: 'Editar condição de pagamento', href: '' },
        ],
      },
    },
  ],
};
