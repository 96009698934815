
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class AlertDialogComponent extends Vue {
  @Prop({
    type: Boolean,
    default: () => false,
  })
  private readonly value!: boolean;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  private readonly persistent!: boolean;

  @Prop({
    type: String,
    default: () => 'Sim',
  })
  private readonly buttonConfirmLabel!: string;

  @Prop({
    type: String,
    default: () => 'Cancelar',
  })
  private readonly buttonCancelLabel!: string;

  @Prop({
    type: String,
    default: () => 'Confirmação',
  })
  private readonly title!: string;

  private confirm() {
    this.$emit('confirm');
  }

  private close() {
    this.$emit('input', false);
  }
}
