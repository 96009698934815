import Vue from 'vue';
import VueTheMask from 'vue-the-mask';
import { VMoney } from 'v-money';
import VuetifyMoney from 'vuetify-money';

import './style/index.sass';

import App from './App.vue';
import filters from './filters/index';
import directives from './directives/index';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Snackbar from './plugins/snackbar';
import Loader from './plugins/loader';
import RenderApp from './services/render.service';

Vue.use(VueTheMask);
Vue.use(VuetifyMoney);
Vue.directive('money', VMoney);
Vue.use(Snackbar);
Vue.use(Loader);

Vue.config.productionTip = false;

function mount() {
  new Vue({
    router,
    store,
    filters,
    directives,
    vuetify,
    render: h => h(App),
  }).$mount('#app');
}
(async () => {
  RenderApp.getRequireInfo().finally(() => {
    mount();
  });
})();
