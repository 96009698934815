
import { Component, Vue } from 'vue-property-decorator';
import { SnackbarInterface } from './interfaces/snackbar.interface';

@Component
export default class SnackbarComponent extends Vue {
  private snackbar: SnackbarInterface = this.$snackbar;

  private created() {
    this.snackbar = this.$snackbar;
  }
}
