const UnitTypeForm = () => import('./components/form/unit-type-form.component.vue');
const UnitTypeView = () => import('./UnitTypes.vue');
const UnitTypeList = () => import('./components/list/unit-type-list.component.vue');

export default {
  path: 'tipo-de-unidades',
  component: UnitTypeView,
  meta: { requiresAuth: true },
  children: [
    {
      path: '/',
      name: 'unit-type-list',
      component: UnitTypeList,
      meta: {
        roles: ['unit_type.list'],
        requiresAuth: true,
        breadcrumbs: [{ text: 'Tipos de Unidades cadastrados', href: '' }],
      },
    },
    {
      path: 'cadastro',
      name: 'unit-type-create',
      component: UnitTypeForm,
      meta: {
        roles: ['unit_type.create'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Tipos de Unidades cadastrados', href: 'unit-type-list' },
          { text: 'Cadastrar tipo de Unidade', href: '' },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'unit-type-edit',
      component: UnitTypeForm,
      meta: {
        roles: ['unit_type.edit'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Tipos de Unidades cadastrados', href: 'unit-type-list' },
          { text: 'Editar tipo de Unidade', href: '' },
        ],
      },
    },
  ],
};
