import { GroupEnum } from '@/modules/Administrative/modules/Users/enum/group.enum';
import { PermissionAccessGroupInterface } from './permission-access-group.interface';

export class PermissionAccessGroup implements PermissionAccessGroupInterface {
  public id: string;

  public name: string;

  constructor(data: any) {
    this.id = data.id || '';
    this.name = data.name || '';
  }

  public get isSuperAdmin(): boolean {
    return this.name === GroupEnum.SUPER_ADMINISTRATOR;
  }

  public get isPartner(): boolean {
    return this.name === GroupEnum.PARTNER;
  }

  public get isBroker(): boolean {
    return this.name === GroupEnum.BROKER;
  }
}
