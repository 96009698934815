import http from '@/services/http.service';

export interface ISimplePaginationService {
  next(): Promise<any>;
  prev(): Promise<any>;
  goToFirst(): Promise<any>;
  setPaginationLinks(links: BackendLinksPaginate);
  nextLink: string;
  prevLink: string;
  firstLink: string;
}

type BackendLinksPaginate = {
  first: string;
  last: string;
  next: string;
  prev: string;
};

export class SimplePagination implements ISimplePaginationService {
  public nextLink: string;

  public prevLink: string;

  public firstLink: string;

  constructor() {
    this.nextLink = '';
    this.prevLink = '';
    this.firstLink = '';
  }

  setPaginationLinks(links: BackendLinksPaginate) {
    this.nextLink = links.next;
    this.prevLink = links.prev;
    this.firstLink = links.first;
  }

  async next() {
    const response = await http.get(this.nextLink).then(({ data }: any) => data);
    this.setPaginationLinks(response.links);
    return response.data;
  }

  async prev() {
    const response = await http.get(this.prevLink).then(({ data }: any) => data);
    this.setPaginationLinks(response.links);
    return response.data;
  }

  async goToFirst() {
    const response = await http.get(this.firstLink).then(({ data }: any) => data);
    this.setPaginationLinks(response.links);
    return response.data;
  }
}
