const CompanyForm = () => import('./components/form/company-form.component.vue');
const CompanyView = () => import('./Companies.vue');
const CompanyList = () => import('./components/list/company-list.component.vue');

export default {
  path: 'empresas',
  component: CompanyView,
  meta: { requiresAuth: true },
  children: [
    {
      path: '/',
      name: 'company-list',
      component: CompanyList,
      meta: {
        roles: ['company.list'],
        requiresAuth: true,
        breadcrumbs: [{ text: 'Empresas cadastradas', href: '' }],
      },
    },
    {
      path: 'cadastro',
      name: 'company-create',
      component: CompanyForm,
      meta: {
        roles: ['company.create'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Empresas cadastradas', href: 'company-list' },
          { text: 'Cadastrar empresa', href: '' },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'company-edit',
      component: CompanyForm,
      meta: {
        roles: ['company.edit'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Empresas cadastradas', href: 'company-list' },
          { text: 'Editar empresa', href: '' },
        ],
      },
    },
  ],
};
