const ChannelsForm = () => import('./components/form/channel-form.component.vue');
const ChannelsView = () => import('./Channels.vue');
const ChannelsList = () => import('./components/list/channels-list.component.vue');

export default {
  path: 'canais-de-venda',
  component: ChannelsView,
  meta: { requiresAuth: true },
  children: [
    {
      path: '/',
      name: 'channel-list',
      component: ChannelsList,
      meta: {
        roles: ['channel.list'],
        requiresAuth: true,
        breadcrumbs: [{ text: 'Canais de venda cadastrados', href: '' }],
      },
    },
    {
      path: 'cadastro',
      name: 'channel-create',
      component: ChannelsForm,
      meta: {
        roles: ['channel.create'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Canais de venda cadastrados', href: 'channel-list' },
          { text: 'Cadastrar Canal de venda', href: '' },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'channel-edit',
      component: ChannelsForm,
      meta: {
        roles: ['channel.edit'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Canais de venda cadastrados', href: 'channel-list' },
          { text: 'Editar Canal de venda', href: '' },
        ],
      },
    },
  ],
};
