import { Mutations } from '../mutations.enum';

export default {
  setUser({ commit }: any, user: any) {
    commit(Mutations.SET_USER, user);
  },
  setCompany({ commit }: any, company: string) {
    commit(Mutations.SET_COMPANY, company);
  },
};
