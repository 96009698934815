import Vue from 'vue';

Vue.directive('show-by-roles', {
  inserted(el, binding, vnode) {
    if (vnode.context && !vnode.context.$permissionAccess.canAccessAction(binding.value)) {
      if (vnode.elm && vnode.elm.parentNode) {
        vnode.elm.parentNode.removeChild(vnode.elm);
      }
    }
  },
});

export default {};
