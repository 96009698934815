
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class DeleteDialogComponent extends Vue {
  @Prop({
    type: Boolean,
    default: () => false,
  })
  private readonly value!: boolean;

  private confirm() {
    this.$emit('confirm');
  }

  private close() {
    this.$emit('input', false);
  }
}
