const ReasonForm = () => import('./components/form/reason-form.component.vue');
const ReasonView = () => import('./Reasons.vue');
const ReasonList = () => import('./components/list/reason-list.component.vue');

export default {
  path: 'motivos',
  component: ReasonView,
  meta: { requiresAuth: true },
  children: [
    {
      path: '/',
      name: 'reason-list',
      component: ReasonList,
      meta: {
        roles: ['reason.list'],
        requiresAuth: true,
        breadcrumbs: [{ text: 'Motivos cadastrados', href: '' }],
      },
    },
    {
      path: 'cadastro',
      name: 'reason-create',
      component: ReasonForm,
      meta: {
        roles: ['reason.create'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Motivos cadastrados', href: 'reason-list' },
          { text: 'Cadastrar motivo', href: '' },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'reason-edit',
      component: ReasonForm,
      meta: {
        roles: ['reason.edit'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Motivos cadastrados', href: 'reason-list' },
          { text: 'Editar motivo', href: '' },
        ],
      },
    },
  ],
};
