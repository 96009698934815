import { AxiosResponse } from 'axios';
import store from '@/store';
import router from '@/router';
import http from '../../../services/http.service';

class LoginService {
  public authenticate(email: string, password: string) {
    return http
      .post('/auth/login', { email, password })
      .then(({ data }: AxiosResponse) => data.data);
  }

  public refreshToken(currentToken: string) {
    const token = currentToken || store.getters.token;

    return http
      .get(`/auth/refresh-token?token=${token}`)
      .then(({ data }: AxiosResponse) => data.data);
  }

  public logout() {
    localStorage.clear();
    store.dispatch('setToken', '');
    router.push({ name: 'login' });
  }
}

export default new LoginService();
