import http from '@/services/http.service';

class NotificationService {
  public getAllNotifications(company_id?: string) {
    return http
      .get('/notification', {
        params: {
          company_id,
        },
      })
      .then(({ data }) => data.data);
  }

  public markAsRead(notifications: string[]) {
    return http.patch('/notification/make-unread', { notifications }).then(({ data }) => data.data);
  }

  public markAsConcluded(notifications: string[]) {
    return http.patch('/notification/make-closed', { notifications }).then(({ data }) => data.data);
  }

  public addTokenPushNotification(token: string) {
    return http.post('/user/set-fcm-token', { token }).then(({ data }) => data.data);
  }
}

export default new NotificationService();
