const SalesFunnelComponent = () => import('./SalesFunnel.vue');
const SalesFunnelWrapper = () => import('./components/sales-funnel-wrapper.component.vue');

export default {
  path: '/funil-de-vendas',
  component: SalesFunnelComponent,
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'sales-funnel',
      component: SalesFunnelWrapper,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};
