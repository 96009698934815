import Vue from 'vue';
import Vuex from 'vuex';
import UserModule from './user/index';
import AuthModule from './auth/index';
import CompanyModule from './company/index';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    AuthModule,
    UserModule,
    CompanyModule,
  },
});
