import UserModule from './modules/Users/route';
import EnterpriseModule from './modules/Enterprises/route';
import ProfileAccessModule from './modules/ProfileAccess/route';
import UnitTypeModule from './modules/UnitTypes/route';
import UnitTypeSubgroupModule from './modules/UnitTypeSubgroups/route';
import CompaniesModule from './modules/Companies/route';
import ReasonModule from './modules/Reasons/route';
import ChannelModule from './modules/Channels/route';
import PaymentConditionModule from './modules/PaymentConditions/route';
import SubstatusModule from './modules/Substatus/route';
import StatusModule from './modules/Status/route';
import ClauseModule from './modules/Clauses/route';
import SellingPointModule from './modules/SellingPoints/route';
import ProductInterestsModule from './modules/ProductInterests/route';
import ChecklistModule from './modules/Checklists/route';

const AdminView = () => import('./administrative.component.vue');

export default {
  path: '/cadastros',
  component: AdminView,
  meta: { requiresAuth: true },
  children: [
    UserModule,
    ProfileAccessModule,
    EnterpriseModule,
    UnitTypeModule,
    UnitTypeSubgroupModule,
    CompaniesModule,
    ReasonModule,
    ChannelModule,
    PaymentConditionModule,
    SubstatusModule,
    StatusModule,
    ClauseModule,
    SellingPointModule,
    ProductInterestsModule,
    ChecklistModule,
  ],
};
