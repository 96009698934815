import { Address } from '@/components/address/entities/address.entity';
import moment from '@/services/moment/moment.service';
import companyService from '../services/companies.service';
import { CompanyAttachmentEnum } from '../components/form/enums/company-attachment-enum.enum';
import Attachment from '../../../../shared/entities/attachment.entity';

export class Company {
  public id: string = '';

  public name: string = '';

  public trading_name: string = '';

  public email: string = '';

  public cellphone: string = '';

  public telephone: string = '';

  public cnpj: string = '';

  public state_registration: string = '';

  public municipal_registration: string = '';

  public has_relationship: boolean | number = false;

  public contract_start_date: string = '';

  public contract_end_date: string = '';

  public active: boolean | number = true;

  public address: Address = new Address();

  public attachments: Attachment[];

  public companyLogoToSend: any = '';

  public companyBackgroundToSend: any = '';

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name || '';
    this.trading_name = data.trading_name || '';
    this.cnpj = data.cnpj || '';
    this.email = data.email || '';
    this.cellphone = data.cellphone || '';
    this.telephone = data.telephone || '';
    this.state_registration = data.state_registration || '';
    this.municipal_registration = data.municipal_registration || '';
    this.contract_start_date = data.contract_start_date || moment().format('YYYY-MM-DD');
    this.contract_end_date = this.formatContractDate(data.contract_end_date);
    this.active = data.active === false ? 0 : 1;
    this.address = new Address(data.address);
    this.attachments = data.attachments || [];
    this.has_relationship = data.has_relationship === true;
  }

  private formatContractDate(contract_end_date: string) {
    return (
      contract_end_date
      || moment()
        .add(1, 'month')
        .format('YYYY-MM-DD')
    );
  }

  private async format() {
    return {
      ...this,
      address: this.address.formatToSave(),
      cnpj: this.removeCharactersText(this.cnpj),
      telephone: this.removeCharactersText(this.telephone),
      cellphone: this.removeCharactersText(this.cellphone),
    };
  }

  private removeCharactersText(text: string) {
    return text ? text.replace(/[^0-9]+/g, '') : '';
  }

  public get activeText() {
    return this.active ? 'Ativo' : 'Inativo';
  }

  public async save(): Promise<any> {
    const data = await this.format();
    return companyService.create(data);
  }

  public async update() {
    const data = await this.format();
    return companyService.update(data);
  }

  public getAttachmentByType(type: CompanyAttachmentEnum) {
    const file = this.attachments.find((it: any) => it.type === type);
    return file ? file.path : undefined;
  }

  public setLogo(attachment: FormData) {
    return companyService.setLogo(this.id, attachment);
  }

  public setBackground(attachment: FormData) {
    return companyService.setBackground(this.id, attachment);
  }

  public removeLogo() {
    const idFile = this.attachments.find((it: Attachment) => it.type === CompanyAttachmentEnum.GENERAL)
      || new Attachment();
    return companyService.removeAttachmentById(idFile.id);
  }

  public removeBackground() {
    const idFile = this.attachments.find((it: Attachment) => it.type === CompanyAttachmentEnum.BACKGROUND)
      || new Attachment();
    return companyService.removeAttachmentById(idFile.id);
  }

  public async delete() {
    return companyService.delete(this.id);
  }
}
