import { Address } from '@/components/address/entities/address.entity';
import store from '@/store';
import moment from '@/services/moment/moment.service';
import unitsService from '../services/units.service';
import { Reserve } from './reserve.entity';

export class Unit {
  public id: string;

  public name: string;

  public active: number;

  public temporaryId: number;

  public disabled: boolean;

  public registration: string;

  public building_area: string;

  public subgroups: string[];

  public total_area: number;

  public total_units: number;

  public finished_at: string;

  public commercial_stock: string;

  public can_do_reserve: boolean;

  public code: string;

  public notes: string;

  public has_relationship: boolean | number = false;

  public address: Address;

  public unit_type: any;

  public unit_type_id: string;

  public sale_value: string;

  public created_at: string;

  public reserve: Reserve;

  public company_id: string = '';

  public block: any = { id: '' };

  public order_number: number = 0;

  constructor(data: any = { totalUnits: 0 }) {
    this.id = data.id;
    this.block = data.block || { id: '' };
    this.temporaryId = data.temporaryId;
    this.active = data.active === false ? 0 : 1;
    this.registration = data.registration || '';
    this.building_area = data.building_area || '';
    this.total_area = data.total_area || '';
    this.finished_at = data.finished_at || moment().format('YYYY-MM-DD');
    this.code = data.code || '';
    this.notes = data.notes || '';
    this.address = new Address(data.address);
    this.unit_type = data.unit_type || {};
    this.unit_type_id = this.getUnitTypeId(data);
    this.subgroups = data.subgroups || [];
    this.created_at = data.created_at || '';
    this.commercial_stock = data.commercial_stock || '';
    this.sale_value = data.sale_value || '';
    this.total_units = data.total_units || 0;
    this.reserve = new Reserve(data.reserve || {});
    this.disabled = false;
    this.can_do_reserve = data.can_do_reserve === true;
    this.order_number = data.order_number || 100;
    this.company_id = data.company && data.company.id ? data.company.id : store.getters.user.company_id;
    this.has_relationship = data.has_relationship === true;
    this.name = data.name || `Unidade ${this.total_units + 1}`;
  }

  private getUnitTypeId(data: any) {
    if (data.unit_type_id) {
      return data.unit_type_id;
    }
    return this.unit_type && this.unit_type.id ? this.unit_type.id : '';
  }

  public async delete() {
    return unitsService.delete(this.id);
  }

  public format() {
    return {
      ...this,
      finished_at: moment(this.finished_at).format('YYYY-MM-DD'),
      address: this.address.formatToSave(),
      subgroups: this.subgroups.map((it: any) => it.id),
      company_id: this.company_id,
    };
  }

  public async update() {
    return unitsService.update(this.id, this.format());
  }

  async changeToBlocked() {
    return unitsService.changeToBlocked(this.id);
  }

  async changeToAvailable() {
    return unitsService.changeToAvailable(this.id);
  }
}
