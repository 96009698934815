
import jwtDecode from 'jwt-decode';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapGetters, mapActions } from 'vuex';
import sidebarItems from './sidebar-items.json';

@Component({
  computed: { ...mapGetters(['user', 'logo', 'background']) },
})
export default class SidebarApp extends Vue {
  @Prop({ type: Boolean, default: () => true })
  private readonly showSidebar!: boolean;

  private item: any = {};

  private logo!: any;

  private miniVariant: boolean = false;

  private background!: any;

  private items = sidebarItems;

  private get backgroundImage() {
    return this.background ? this.background : '/assets/images/background/sidebar-background.jpg';
  }

  private get logoImage() {
    return this.logo ? this.logo : '/assets/images/logo/solutte-logo.png';
  }

  private itemsToSidebar() {
    return this.items.reduce((acc: Array<any>, value: any) => {
      const modules = value.items.filter((module: any) => {
        if (module && this.$permissionAccess) {
          return this.$permissionAccess.canAccessAction(module.roles);
        }
        return false;
      });
      const hasModuleAvailable = modules.length;
      if (!hasModuleAvailable) return acc;
      acc.push({
        ...value,
        items: modules,
      });
      return acc;
    }, []);
  }

  private appendIcon(itemMenu: any) {
    return itemMenu.items && itemMenu.items.length > 0 ? 'keyboard_arrow_down' : '';
  }

  private closeDialog(data: boolean) {
    if (this.$vuetify.breakpoint.smAndDown && !data) {
      this.$emit('toggle-sidebar');
    }
  }
}
