
import { Component, Vue } from 'vue-property-decorator';
import { LoaderInterface } from './interfaces/loader.interface';

@Component
export default class LoaderComponent extends Vue {
  private loader: LoaderInterface = this.$loader;

  private created() {
    this.loader = this.$loader;
  }
}
