import { AxiosResponse } from 'axios';
import http from '@/services/http.service';
import { ISimplePaginationService, SimplePagination } from '@/entities/pagination/simple-pagination';
import { IEditAllUnitsPayload } from './edit-all-units-payload.interface';

class UnitService {
  public simplePaginationService: ISimplePaginationService;

  constructor(simplePaginationService: ISimplePaginationService) {
    this.simplePaginationService = simplePaginationService;
  }

  public getUnitById(id: string) {
    return http.get(`/unit/${id}`).then(({ data }: AxiosResponse) => data.data);
  }

  public update(id: string, payload: any) {
    return http.put(`/unit/${id}`, payload).then(({ data }: AxiosResponse) => data.data);
  }

  public delete(id: string) {
    return http.delete(`/unit/${id}`).then(({ data }: AxiosResponse) => data.data);
  }

  public getUnitTypesByCompanyId(companyId: string) {
    return http
      .get('/unit-type', {
        params: {
          company_id: companyId,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }

  public getUnitTypes(params: any = {}) {
    return http.get('/unit-type', params).then(({ data }: AxiosResponse) => data.data);
  }

  public getSubGroups(params: any = {}) {
    return http.get('/unit-type-subgroup', params).then(({ data }: AxiosResponse) => data.data);
  }

  public getSubGroupsById(id: string) {
    return http.get(`/unit-type-subgroup/${id}`).then(({ data }: AxiosResponse) => data.data);
  }

  public editAllUnits(payload: IEditAllUnitsPayload) {
    return http.put('/unit/batch-all', payload).then(({ data }: AxiosResponse) => data.data);
  }

  public blockUnits(block_id: string, units: string[]) {
    return http
      .put('/unit/block', { block_id, units })
      .then(({ data }: AxiosResponse) => data.data);
  }

  public getCommercialStocks() {
    return http.get('/unit/commercial-stocks').then(({ data }: AxiosResponse) => data.data);
  }

  public getUnitsByEnterpriseId(enterpriseId: string) {
    return http
      .get('/unit/basic', {
        params: {
          enterprise_id: enterpriseId,
          active: 1,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }

  public saveUnitsInLots(units: any, block_id: string) {
    return http
      .put('/unit/batch', { block_id, units })
      .then(({ data }: AxiosResponse) => data.data);
  }

  public saveUnitsTable(units: any, block_id: string) {
    return http
      .put('/unit/batch-basic', { block_id, units })
      .then(({ data }: AxiosResponse) => data.data);
  }

  public changeToBlocked(unitId: string) {
    return http.post(`/unit/${unitId}/blocked`).then(({ data }: AxiosResponse) => data.data);
  }

  public changeToAvailable(unitId: string) {
    return http.post(`/unit/${unitId}/available`).then(({ data }: AxiosResponse) => data.data);
  }
}

const simplePaginationService = new SimplePagination();
export default new UnitService(simplePaginationService);
