export default class Attachment {
  public id: any | string;

  public name: any | string;

  public path: any | string;

  public file: any;

  public type: any | string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name || '';
    this.path = data.path;
    this.file = data.file;
    this.type = data.type || '';
  }
}
