const ReportViewComponent = () => import('./Report.vue');
const ReportWrapperComponent = () => import('./report-wrapper.component.vue');

export default {
  path: '/relatorios',
  component: ReportViewComponent,
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'report',
      component: ReportWrapperComponent,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};
