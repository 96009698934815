import Axios, { AxiosInstance } from 'axios';
import http from '@/services/http.service';

class AddressService {
  public httpViaCep: AxiosInstance;

  constructor() {
    this.httpViaCep = Axios.create({
      baseURL: 'https://viacep.com.br/ws',
    });
  }

  public getAddressByCep(cep: string) {
    return this.httpViaCep.get(`${cep}/json`).then(({ data }) => data);
  }

  public getStates() {
    return http.get('/state').then(({ data }) => data.data);
  }

  public getCitiesByStateId(stateId: string) {
    return http.get(`/city?state_id=${stateId}`).then(({ data }) => data.data);
  }
}

export default new AddressService();
