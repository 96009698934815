
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Address } from './entities/address.entity';
import rulesService from '../../services/rules.service';
import addressService from './services/address.service';
import { AddressViaCepInterface } from './interfaces/address-via-cep.interface';

@Component
export default class AddressComponent extends Vue {
  @Prop({ type: Object, default: () => new Address() })
  private readonly address!: Address;

  @Prop({ type: Object, default: () => ({}) })
  private readonly hiddenItems!: any;

  @Prop({
    type: Object,
    default: () => ({
      cep: 'Cep',
      address: 'Logradouro',
      number: 'Número',
      neighborhood: 'Bairro',
      state: 'Estado',
      city: 'Cidade',
      complement: 'Complemento',
    }),
  })
  private readonly labelFields!: any;

  @Prop({
    type: Object,
    default: () => ({
      cep: [rulesService.required],
      address: [rulesService.required],
      number: [rulesService.required],
      neighborhood: [rulesService.required],
      state: [rulesService.required],
      city: [rulesService.required],
      complement: [],
    }),
  })
  private readonly requiredFields!: any;

  @Prop({ type: Boolean, default: () => false })
  private readonly outlined!: boolean;

  @Prop({ type: Boolean, default: () => false })
  private readonly dense!: boolean;

  @Prop({ type: Boolean, default: () => true })
  private readonly searchViaCep!: boolean;

  private states: any[] = [];

  private cities: any[] = [];

  private loadingAddress: boolean = false;

  private rules = {
    required: rulesService.required,
  };

  private get hasHiddenItems() {
    return Object.keys(this.hiddenItems).length > 0;
  }

  private get hasCepCompleted() {
    const cep = this.address.cep.replace('-', '');
    return cep.length === 8;
  }

  private get disableCityField() {
    return !this.hasHiddenItems && (!this.hasCepCompleted || !this.address.city.state.id);
  }

  private searchByCep(cep: string) {
    this.address.cep = cep;
    if (this.hasCepCompleted && this.searchViaCep) {
      this.loadingAddress = true;
      addressService
        .getAddressByCep(cep)
        .then((data: AddressViaCepInterface) => {
          this.formatAddress(data);
        })
        .catch(() => {
          this.loadingAddress = false;

          this.$snackbar.open({
            text: 'Ocorreu um erro ao buscar os dados pelo cep',
            color: 'danger',
          });
        });
    }
  }

  private async formatAddress(data: AddressViaCepInterface) {
    const stateId = await this.findStateIdByName(data.uf);
    if (!stateId) {
      this.loadingAddress = false;
      this.setAddress(data);
      return;
    }
    await this.getCitiesByStateId(stateId);
    const cityId = await this.findCityIdByName(data.localidade);

    const address = {
      ...data,
      uf: stateId,
      localidade: cityId,
    };
    this.setAddress(address);
    this.loadingAddress = false;
  }

  private setAddress(address: AddressViaCepInterface) {
    const addressFormatted = Address.formatAdressViaCep(address);
    this.$emit('set-address-by-cep', addressFormatted);
  }

  private findStateIdByName(stateName: string) {
    const state = this.states.find((it: any) => it.initials === stateName);
    return state ? state.id : '';
  }

  private async findCityIdByName(cityName: string) {
    const city = this.cities.find((it: any) => it.name === cityName);
    return city ? city.id : '';
  }

  private async getStates() {
    if (this.address.city.state.id) {
      this.getCitiesByStateId(this.address.city.state.id);
    }
    this.states = await addressService.getStates();
  }

  private async getCitiesByStateId(stateId: string) {
    const state = stateId || this.address.city.state.id;
    this.cities = await addressService.getCitiesByStateId(state);
  }

  private created() {
    this.getStates();
  }
}
