const UserForm = () => import('./components/form/user-form.component.vue');
const UsersView = () => import('./Users.vue');
const UserList = () => import('./components/list/user-list.component.vue');

export default {
  path: 'usuarios',
  component: UsersView,
  meta: { requiresAuth: true },
  children: [
    {
      path: '/',
      name: 'user-list',
      component: UserList,
      meta: {
        requiresAuth: true,
        roles: ['user.list'],
        breadcrumbs: [{ text: 'Usuários cadastrados', href: '' }],
      },
    },
    {
      path: 'cadastro',
      name: 'user-create',
      component: UserForm,
      meta: {
        roles: ['user.create'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Usuários cadastrados', href: 'user-list' },
          { text: 'Cadastrar usuário', href: '' },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'user-edit',
      component: UserForm,
      meta: {
        roles: ['user.edit'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Usuários cadastrados', href: 'user-list' },
          { text: 'Editar usuário', href: '' },
        ],
      },
    },
  ],
};
