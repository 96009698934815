const ClientsView = () => import('./Clients.vue');
const ClientsForm = () => import('./components/form/client-form.component.vue');
const ClientsList = () => import('./components/list/client-list.component.vue');
const ReminderCalendar = () => import('./components/reminders/Reminder.vue');
const TasksComponent = () => import('./components/tasks/Tasks.vue');
const Transfer = () => import('./components/transfer/Transfer.vue');

export default {
  path: '/clientes',
  component: ClientsView,
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'client-list',
      component: ClientsList,
      meta: {
        requiresAuth: true,
        breadcrumbs: [{ text: 'Clientes cadastrados', href: '' }],
      },
    },
    {
      path: 'cadastrar',
      name: 'client-form',
      component: ClientsForm,
      meta: {
        requiresAuth: true,
        breadcrumbs: [
          { text: 'clientes cadastrados', href: 'client-list' },
          { text: 'Cadastrar clientes', href: '' },
        ],
      },
    },
    {
      path: 'editar/:id/:tab?',
      name: 'client-edit',
      component: ClientsForm,
      meta: {
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Clientes cadastrados', href: 'client-list' },
          { text: 'Editar cliente', href: '' },
        ],
      },
    },
    {
      path: 'meus-lembretes',
      name: 'reminder-list',
      component: ReminderCalendar,
      meta: {
        requiresAuth: true,
        breadcrumbs: [{ text: 'Meus Lembretes', href: 'reminder-list' }],
      },
    },
    {
      path: 'minhas-tarefas',
      name: 'tasks-list',
      component: TasksComponent,
      meta: {
        requiresAuth: true,
        breadcrumbs: [{ text: 'Minhas tarefas', href: 'tasks-list' }],
      },
    },
    {
      path: 'transferencia-de-resposavel',
      name: 'transfer',
      component: Transfer,
      meta: {
        requiresAuth: true,
        breadcrumbs: [{ text: 'Transferência de propostas ', href: 'transfer' }],
      },
    },
  ],
};
