import notificationService from '@/components/header/services/notification.service';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyARnYa0HJq1_AudI2ZAfWEQOOVyw8tS-CA',
  authDomain: 'crm-solutte.firebaseapp.com',
  projectId: 'crm-solutte',
  storageBucket: 'crm-solutte.appspot.com',
  messagingSenderId: '957990486736',
  appId: '1:957990486736:web:d73f47d44799389d6794d5',
  measurementId: 'G-XDT28QM4DK',
};

initializeApp(firebaseConfig);

function generateToken(messaging: any) {
  const hasToken = localStorage.getItem('token-push');
  if (hasToken) return;
  getToken(messaging, {
    vapidKey:
      'BGtMEKDg0jC9OMv-RKp0WBtcdQYpl75kivbm-nkEGPb0707AuSFdeAIGi5Ng5GS7MDTnjaHgG-qO6zvRwk9qVow',
  }).then((currentToken: string) => {
    if (currentToken) {
      localStorage.setItem('token-push', currentToken);
      notificationService.addTokenPushNotification(currentToken);
    }
  });
}

function createMessaging() {
  if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development') {
    const messaging = getMessaging();

    generateToken(messaging);
    return messaging;
  }
  return '';
}

export default {
  install(Vue: any) {
    function addHandlerMessage(messaging: any) {
      onMessage(messaging, (payload: any) => {
        Vue.prototype.$snackbar.open({
          text: `${payload.notification.title} - ${payload.notification.body}`,
          color: 'info',
          multiLine: !!payload.notification.body,
          buttonColor: '#fff',
          right: true,
          vertical: true,
          timeout: 9000,
        });
      });
    }
    if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development') {
      const firebaseMessaging = createMessaging();
      addHandlerMessage(firebaseMessaging);
      Vue.prototype.$firebase = firebaseMessaging;
    }
  },
};
