import Vue from 'vue';

export default Vue.filter('cep-pattern', (value: string) => {
  const data = String(value);
  try {
    if (data) {
      return data.replace(/[^0-9]/g, '').replace(/^(\d{5})(\d)/g, '$1-$2');
    }
    return '';
  } catch (err) {
    throw new Error(`An error ocurred when format cep => ${err}`);
  }
});
