const ProfileAccessView = () => import('./profile-access.component.vue');
const ProfileList = () => import('./components/list/profile-list.component.vue');
const Permissions = () => import('./components/permissions/profile-permissions.component.vue');

export default {
  path: 'perfis-de-acesso',
  component: ProfileAccessView,
  meta: { requiresAuth: true },
  children: [
    {
      path: '/',
      name: 'profile-access-list',
      component: ProfileList,
      meta: {
        roles: ['role.list'],
        requiresAuth: true,
        breadcrumbs: [{ text: 'Perfis cadastrados', href: '' }],
      },
    },
    {
      path: 'gerenciar-permissoes/:id',
      name: 'manager-permissions',
      component: Permissions,
      meta: {
        roles: ['role.edit', 'role.create'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Perfis cadastrados', href: 'profile-access-list' },
          { text: 'Gerenciar permissoes', href: '' },
        ],
      },
    },
  ],
};
