const StatusForm = () => import('./components/form/status-form.component.vue');
const StatusView = () => import('./Status.vue');
const StatusList = () => import('./components/list/status-list.component.vue');

export default {
  path: 'status',
  component: StatusView,
  meta: { requiresAuth: true },
  children: [
    {
      path: '/',
      name: 'status-list',
      component: StatusList,
      meta: {
        roles: ['status.list'],
        requiresAuth: true,
        breadcrumbs: [{ text: 'status cadastrados', href: '' }],
      },
    },
    {
      path: 'cadastro',
      name: 'status-create',
      component: StatusForm,
      meta: {
        roles: ['status.create'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'status cadastrados', href: 'status-list' },
          { text: 'Cadastrar status', href: '' },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'status-edit',
      component: StatusForm,
      meta: {
        roles: ['status.edit'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'status cadastrados', href: 'status-list' },
          { text: 'Editar status', href: '' },
        ],
      },
    },
  ],
};
