import EnterpriseUnitsForm from './components/form/components/enterprise-units/units/components/enterprise-units-form.component.vue';

const EnterpriseWrapper = () => import('./components/form/enterprise-wrapper.component.vue');
const EnterpriseView = () => import('./Enterprises.vue');
const EnterpriseList = () => import('./components/list/enterprise-list.component.vue');

export default {
  path: 'empreendimentos',
  component: EnterpriseView,
  meta: { requiresAuth: true },
  children: [
    {
      path: '/',
      name: 'enterprise-list',
      component: EnterpriseList,
      meta: {
        roles: ['enterprise.list'],
        requiresAuth: true,
        breadcrumbs: [{ text: 'Empreendimentos cadastrados', href: '' }],
      },
    },
    {
      path: 'cadastro',
      name: 'enterprise-create',
      component: EnterpriseWrapper,
      meta: {
        roles: ['enterprise.create'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Empreendimentos cadastrados', href: 'enterprise-list' },
          { text: 'Cadastrar empreendimento', href: '' },
        ],
      },
    },
    {
      path: 'editar/:id/:tab?/:blockId?',
      name: 'enterprise-edit',
      component: EnterpriseWrapper,
      meta: {
        roles: ['enterprise.list'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Empreendimentos cadastrados', href: 'enterprise-list' },
          { text: 'Editar empreendimento', href: '' },
        ],
      },
    },
    {
      path: 'editar/:enterprise_id/unidades/editar/:id',
      name: 'unit-edit',
      component: EnterpriseUnitsForm,
      meta: {
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Empreendimentos cadastrados', href: 'enterprise-list' },
          { text: 'Editar Unidade', href: 'unit-edit' },
        ],
      },
    },
  ],
};
