import moment from 'moment-timezone';
import {
  NotificationIconsEnum,
  NotificationTitleEnum,
  NotificationRedirectEnum,
  NotificationTypeEnum,
} from '../enums/notification.enum';

export class Notification {
  public id: string;

  public active: string;

  public title: string;

  public message: string;

  public type: string;

  public received_by: string;

  public model: any;

  public created_by: string;

  public updated_by: string;

  public read_at: string;

  public created_at: string;

  public redirectId: string;

  constructor(data: any = {}) {
    this.id = data.id || '';
    this.active = data.active || '';
    this.title = data.title || 'Nova notificação';
    this.message = data.message || '';
    this.type = data.type || '';
    this.received_by = data.received_by || '';
    this.redirectId = data.redirect_id || '';
    this.model = data.model || {};
    this.read_at = data.read_at || '';
    this.created_by = data.created_by ? data.created_by.name : '';
    this.created_at = data.created_at || moment().format('DD/MM/YYYY HH:mm:ss');
    this.updated_by = data.updated_by ? data.updated_by.name : '';
  }

  public get icon() {
    const icons: any = NotificationIconsEnum;
    return icons[this.type];
  }

  public get dateFormatted() {
    return this.created_at ? moment(this.created_at).format('DD/MM/YYYY') : '';
  }

  public get titleNotification() {
    const titles: any = NotificationTitleEnum;
    return titles[this.type];
  }

  public get redirect() {
    const redirects: any = NotificationRedirectEnum;
    return redirects[this.type];
  }

  public get notificationType() {
    const type: any = NotificationTypeEnum;
    return type[this.type];
  }
}
