
import { Component, Vue } from 'vue-property-decorator';

import { mapGetters } from 'vuex';
import BreadcrumbsComponent from './breadcrumbs.component.vue';
import loginService from '../../modules/Login/services/login.service';
import notificationService from './services/notification.service';
import { Notification } from './entities/notification.entity';
import AlertDialog from '../dialogs/alert-dialog.component .vue';

@Component({
  components: { BreadcrumbsComponent, AlertDialog },
  computed: {
    ...mapGetters(['user']),
  },
})
export default class HeaderApp extends Vue {
  public user!: any;

  private notifications: Notification[] = [];

  private showNotificationMenu: boolean = false;

  private showDialogLogout: boolean = false;

  private get numberOfNotifications() {
    return this.notificationsActive.filter((it: Notification) => !it.read_at).length;
  }

  private get notificationsActive() {
    return this.notifications.filter((it: Notification) => it.active);
  }

  get isSuperAdmin() {
    return this.$permissionAccess ? this.$permissionAccess.group.isSuperAdmin : false;
  }

  get companyName() {
    if (this.user && this.user.company) {
      return this.user.company.name;
    }
    return 'Não identificado';
  }

  private closeDialogLogout() {
    this.showDialogLogout = false;
  }

  private openDialogLogout() {
    this.showDialogLogout = true;
  }

  private toggleSidebar() {
    this.$emit('toggle-sidebar');
  }

  private goToRoute(notification: Notification) {
    this.$router.push({
      name: notification.redirect,
      params: { id: notification.redirectId, tab: notification.notificationType },
    });
  }

  private logout() {
    loginService.logout();
  }

  private async markAsClosed(notification: Notification) {
    if (!notification.id) return;
    notificationService
      .markAsConcluded([notification.id])
      .then(() => {
        this.$snackbar.open({
          text: 'Notificação removida',
          color: 'success',
          buttonColor: 'white',
        });
        this.getNotifications();
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Erro ao excluir notificação',
          color: 'danger',
          buttonColor: 'white',
        });
      });
  }

  private async markAllAsRead() {
    if (!this.notificationsActive.length) return;
    const notificationsIds = this.notificationsActive.map((it: Notification) => it.id);
    await notificationService.markAsRead(notificationsIds);
    this.getNotifications();
  }

  private async getNotifications() {
    const companyId = this.$store.getters.user.company_id;
    const notifications: any[] = await notificationService.getAllNotifications(companyId);

    this.notifications = notifications.map((it: any) => new Notification(it));
  }

  private createIntervalToGetNotification() {
    setInterval(() => {
      this.getNotifications();
    }, 60000);
  }

  private created() {
    this.getNotifications();
    this.createIntervalToGetNotification();
  }
}
