import { times } from 'lodash';
import { GroupEnum } from '@/modules/Administrative/modules/Users/enum/group.enum';
import { PermissionAccessInterface } from './permission-access.interface';
import { PermissionAccessRolesInterface } from './Roles/permission-access-group.interface';
import { PermissionAccessGroupInterface } from './Group/permission-access-group.interface';
import { PermissionAccessGroup } from './Group/permission-access-group.entity';
import { PermissionsAccessItems } from './Roles/permission-access-item.interface';

export default class PermissionAccessService implements PermissionAccessInterface {
  public group: PermissionAccessGroupInterface;

  private roles: PermissionAccessRolesInterface;

  constructor(data: any) {
    this.group = new PermissionAccessGroup(data.group);
    this.roles = data.roles && data.roles.length ? data.roles[0] : { permissions: [] };
  }

  public get isBroker() {
    return this.roles.name === GroupEnum.BROKER;
  }

  public get isSalesManager() {
    return this.roles.name === GroupEnum.SALES_MANAGER;
  }

  public get isManager() {
    return this.roles.name === GroupEnum.MANAGER;
  }

  private getAvailableModules() {
    return this.roles.permissions.map((it: PermissionsAccessItems) => it.module);
  }

  private getAvailablePermissions() {
    return this.roles.permissions.map((it: PermissionsAccessItems) => it.name);
  }

  public canAccessModule(modules: Array<string> = []): boolean {
    if (this.group.isSuperAdmin) return true;
    const isModulePrivate = modules.length;
    if (!isModulePrivate) return true;
    const availableModules: Array<string> = this.getAvailableModules();
    return availableModules.some((it: string) => modules.includes(it));
  }

  public canAccessAction(actions: Array<string> = []): boolean {
    if (this.group.isSuperAdmin) return true;
    const isModulePrivate = actions.length;
    if (!isModulePrivate) return true;
    const availablePermissions: Array<string> = this.getAvailablePermissions();
    return availablePermissions.some((it: string) => actions.includes(it));
  }
}
