
import { Component, Vue } from 'vue-property-decorator';
import rulesService from '@/services/rules.service';
import DatePicker from '@/components/date-picker/date-picker.component.vue';
import DeleteDialog from '@/components/dialogs/delete-dialog.component.vue';

import AddressComponent from '@/components/address/address.component.vue';
import AlertError from '@/components/alert-error/alert-error.component.vue';
import { Unit } from '../entities/unit.entity';
import unitsService from '../services/units.service';

import UnitFormComponent from './unit-form.component.vue';
import { CommercialStocksEnum } from '../enums/commercial-stocks.enum';

@Component({
  components: {
    AddressComponent,
    AlertError,
    DatePicker,
    DeleteDialog,
    UnitFormComponent,
  },
})
export default class unitUnitsForm extends Vue {
  public $refs: any;

  private unit: Unit = new Unit();

  private errors: any = {};

  private showDialogDelete: boolean = false;

  private get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  private get color() {
    return this.unit.commercial_stock === CommercialStocksEnum.Bloqueado ? 'danger' : 'info';
  }

  private get showChangeAvaiableButton() {
    return (
      this.unit.commercial_stock === CommercialStocksEnum.Bloqueado
      || this.unit.commercial_stock === CommercialStocksEnum.Disponivel
    );
  }

  private get buttonLabel() {
    return this.unit.commercial_stock === CommercialStocksEnum.Bloqueado
      ? 'Liberar unidade'
      : 'Bloquear unidade';
  }

  private get buttonColor() {
    return this.unit.commercial_stock === CommercialStocksEnum.Bloqueado ? 'success' : 'danger';
  }

  private changeToBlocked() {
    this.errors = [];
    this.unit
      .changeToBlocked()
      .then(() => {
        this.$snackbar.open({
          text: 'Unidade bloqueada com sucesso.',
          color: 'success',
          buttonColor: '#fff',
        });
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Erro ao bloquear unidade.',
          color: 'danger',
          buttonColor: '#fff',
        });

        this.errors = { erro: [err.response.data.message] };
        throw new Error(err);
      })
      .finally(() => {
        this.getUnitById();
      });
  }

  private changeToAvailable() {
    this.errors = [];
    this.unit
      .changeToAvailable()
      .then(() => {
        this.$snackbar.open({
          text: 'Unidade liberada com sucesso.',
          color: 'success',
          buttonColor: '#fff',
        });
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Erro ao liberar unidade.',
          color: 'danger',
          buttonColor: '#fff',
        });
        this.errors = { erro: [err.response.data.message] };
        throw new Error(err);
      })
      .finally(() => {
        this.getUnitById();
      });
  }

  private changeCommercialStock() {
    if (this.unit.commercial_stock !== CommercialStocksEnum.Bloqueado) {
      this.changeToBlocked();
      return;
    }
    this.changeToAvailable();
  }

  private async getUnitById() {
    const { id } = this.$route.params;
    const data = await unitsService.getUnitById(id);
    this.unit = new Unit(data);
  }

  private setShowDialogDelete() {
    this.showDialogDelete = true;
  }

  private closeDialogDelete() {
    this.showDialogDelete = false;
  }

  private deleteItem() {
    this.unit
      .delete()
      .then(() => {
        this.$snackbar.open({
          text: 'unidade excluido com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: err.response.data.message,
          color: 'danger',
          buttonColor: '#fff',
        });
        throw new Error(err);
      })
      .finally(() => {
        this.goToList();
      });
  }

  private async goToList() {
    const { enterprise_id } = this.$route.params;
    this.$router.push({
      name: 'enterprise-edit',
      params: {
        id: enterprise_id,
        tab: 'unidades',
        blockId: this.unit.block.id,
      },
    });
  }

  private async submit() {
    if (!this.$refs.form.validate()) return;
    this.edit();
  }

  private async edit() {
    this.unit
      .update()
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Unidade alterada com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.goToList();
      })
      .catch((err: any) => {
        this.errors = err.response.data.errors;
      });
  }

  private created() {
    this.getUnitById();
  }
}
