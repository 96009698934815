const Login = () => import('./Login.vue');
const Auth = () => import('./auth.component.vue');
const resetPassword = () => import('./reset-password.component.vue');
const NewPassword = () => import('./new-password.component.vue');

export default {
  path: '/auth',
  component: Login,
  children: [
    {
      path: '',
      name: 'login',
      component: Auth,
      meta: {
        authView: true,
      },
    },
    {
      path: 'esqueci-minha-senha',
      name: 'reset-password',
      component: resetPassword,
      meta: {
        authView: true,
      },
    },
    {
      path: 'cadastrar-nova-senha/:userId',
      name: 'new-password',
      component: NewPassword,
      meta: {
        authView: true,
      },
    },
  ],
};
