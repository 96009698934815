import { AddressViaCepInterface } from '../interfaces/address-via-cep.interface';
import { CityInterface } from '../interfaces/city.interface';
import { City } from './city.entity';

export class Address {
  public id: string;

  public cep: string;

  public address: string;

  public number: string;

  public complement: null;

  public reference: null;

  public neighborhood: string;

  public city: CityInterface;

  constructor(data: any = {}) {
    this.id = data.id || '';
    this.cep = data.cep || '';
    this.address = data.address || '';
    this.number = data.number || '';
    this.complement = data.complement || '';
    this.reference = data.reference || '';
    this.neighborhood = data.neighborhood || '';
    this.city = new City(data.city || {});
  }

  public static formatAdressViaCep(address: AddressViaCepInterface) {
    return {
      cep: address.cep,
      address: address.logradouro,
      neighborhood: address.bairro,
      city: new City({ id: address.localidade, state: { id: address.uf } }),
      number: address.numero,
    };
  }

  public get hasCepCompleted() {
    const cep = this.cep.replace('-', '');
    return cep.length === 8;
  }

  public formatToSave() {
    if (!this.cep) return undefined;
    return {
      ...this,
      cep: this.cep.replace('-', ''),
      city_id: this.city.id,
      number: String(this.number).trim() ? String(this.number).trim() : undefined,
    };
  }
}
