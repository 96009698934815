import Vue from 'vue';
import PermissionAccessService from './permission-access.service';

export default class PermissionAccessInstall {
  static async installPlugin(user: any) {
    return new Promise((resolve) => {
      const plugin = {
        install(VueInstance: any) {
          VueInstance.prototype.$permissionAccess = new PermissionAccessService(user);
          resolve(true);
        },
      };
      Vue.use(plugin);
    });
  }
}
