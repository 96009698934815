import { StateInterface } from '../interfaces/state.interface';

export class State implements StateInterface {
  public id: string;

  public name: string;

  constructor(data: any = {}) {
    this.id = data.id || '';
    this.name = data.name || '';
  }
}
