const SellingPointForm = () => import('./components/form/selling-point-form.component.vue');
const SellingPointView = () => import('./SellingPoints.vue');
const SellingPointList = () => import('./components/list/selling-point-list.component.vue');

export default {
  path: 'pontos-de-venda',
  component: SellingPointView,
  meta: { requiresAuth: true },
  children: [
    {
      path: '/',
      name: 'selling-point-list',
      component: SellingPointList,
      meta: {
        roles: ['selling_point.list'],
        requiresAuth: true,
        breadcrumbs: [{ text: 'Pontos de venda cadastradas', href: '' }],
      },
    },
    {
      path: 'cadastro',
      name: 'selling_point-create',
      component: SellingPointForm,
      meta: {
        roles: ['selling_point.create'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Pontos de venda cadastradas', href: 'selling-point-list' },
          { text: 'Cadastrar ponto de venda', href: '' },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'selling_point-edit',
      component: SellingPointForm,
      meta: {
        roles: ['selling_point.edit'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Pontos de venda cadastradas', href: 'selling-point-list' },
          { text: 'Editar ponto de venda', href: '' },
        ],
      },
    },
  ],
};
