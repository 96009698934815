
import { mapGetters } from 'vuex';
import { Component, Vue, Emit } from 'vue-property-decorator';
import HeaderApp from '../header/header.component.vue';

@Component({ components: { HeaderApp }, computed: { ...mapGetters(['isAuthenticated']) } })
export default class ViewApp extends Vue {
  private isAuthenticated?: string;

  @Emit('toggle-sidebar')
  private toggleSidebar() {}
}
