
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { Component, Watch } from 'vue-property-decorator';
import AdminSelectCompanyDialog from './components/admin-select-company/admin-select-company.dialog.vue';
import ViewApp from './components/view/view.component.vue';
import SidebarApp from './components/sidebar/sidebar.component.vue';
import SnackbarComponent from './components/snackbar/snackbar.component.vue';
import LoaderComponent from './components/loader/loader.component.vue';
import Firebase from './plugins/firebase.service';

@Component({
  components: {
    ViewApp,
    SidebarApp,
    SnackbarComponent,
    LoaderComponent,
    AdminSelectCompanyDialog,
  },
  computed: { ...mapGetters(['isAuthenticated']) },
})
export default class App extends Vue {
  private showSidebar: boolean = this.$vuetify.breakpoint.smAndUp === true;

  private isAuthenticated?: string;

  public showAdminSelectCompany: boolean = false;

  public render: boolean = false;

  private toggleSidebar(value: boolean = !this.showSidebar) {
    this.showSidebar = value;
  }

  private get renderSidebar(): boolean {
    if (!this.$route || !this.$route.meta) return false;
    if (this.$route.meta.authView) return false;
    if (this.isAuthenticated && this.$permissionAccess) {
      return true;
    }
    return false;
  }

  refresh() {
    this.$loader.open();
    this.render = false;
    setTimeout(() => {
      this.render = true;
      this.$loader.close();
    }, 800);
  }

  @Watch('isAuthenticated')
  onIsAuthChanged() {
    this.showAdminSelectCompany = this.$permissionAccess.group.isSuperAdmin;
  }

  mounted() {
    if (this.isAuthenticated) {
      this.showAdminSelectCompany = this.$permissionAccess.group.isSuperAdmin;
      Vue.use(Firebase);
    }
    this.render = !this.showAdminSelectCompany;
  }
}
