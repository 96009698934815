const SubstatusForm = () => import('./components/form/substatus-form.component.vue');
const SubstatusView = () => import('./Substatus.vue');
const SubstatusList = () => import('./components/list/substatus-list.component.vue');

export default {
  path: 'substatus',
  component: SubstatusView,
  meta: { requiresAuth: true },
  children: [
    {
      path: '/',
      name: 'sub_status-list',
      component: SubstatusList,
      meta: {
        roles: ['sub_status.list'],
        requiresAuth: true,
        breadcrumbs: [{ text: 'Substatus cadastrados', href: '' }],
      },
    },
    {
      path: 'cadastro',
      name: 'sub_status-create',
      component: SubstatusForm,
      meta: {
        roles: ['sub_status.create'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'substatus cadastrados', href: 'sub_status-list' },
          { text: 'Cadastrar substatus', href: '' },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'sub_status-edit',
      component: SubstatusForm,
      meta: {
        roles: ['sub_status.edit'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Substatus cadastrados', href: 'sub_status-list' },
          { text: 'Editar substatus', href: '' },
        ],
      },
    },
  ],
};
