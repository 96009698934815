import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import store from '@/store';
import router from '@/router';

function getBaseUrl() {
  const urls: any = {
    development: process.env.VUE_APP_BASE_URL,
    homolog: process.env.VUE_APP_URL_HOMOLOG,
    production: process.env.VUE_APP_URL_PROD,
  };

  return urls[process.env.NODE_ENV];
}
const baseURL = getBaseUrl();
const http = axios.create({
  baseURL,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});
function refreshToken(currentToken: string) {
  const token = currentToken || store.getters.token;

  return http
    .get(`/auth/refresh-token?token=${token}`)
    .then(({ data }: AxiosResponse) => data.data.access_token);
}
http.interceptors.request.use((axiosRequestConfig: AxiosRequestConfig): AxiosRequestConfig => {
  const { token, user } = store.getters;
  axiosRequestConfig.params = { ...axiosRequestConfig.params, company_id: user.company_id };
  axiosRequestConfig.headers.Authorization = `Bearer ${token}`;
  return axiosRequestConfig;
});

http.interceptors.response.use(
  (axiosResponse: AxiosResponse) => axiosResponse,
  (axiosError: AxiosError): any => {
    const request = axiosError.config;
    const { token } = store.getters;

    if (axiosError.response && axiosError.response.status === 401) {
      refreshToken(token)
        .then((newToken: string) => {
          store.dispatch('setToken', newToken);
          return axios(request);
        })
        .catch((err: any) => {
          router.push({ name: 'login' });
          throw new Error(`Não foi possivel setar o novo token ${err}`);
        });
    }
    if (axiosError.response && axiosError.response.status === 403) {
      router.push({ name: 'not-authorized' });
    }

    return Promise.reject(axiosError);
  },
);

export default http;
