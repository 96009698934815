import moment from '@/services/moment/moment.service';

export class Reserve {
  public id: string;

  public notes: string;

  public expires_at: string;

  public created_by: any;

  constructor(data: any = {}) {
    this.id = data && data.id ? data.id : '';
    this.notes = data && data.notes ? data.notes : '';
    this.expires_at = data && data.expires_at ? data.expires_at : moment();
    this.created_by = data && data.created_by ? data.created_by : {};
  }

  public get expiresDate() {
    return moment(this.expires_at).format('DD/MM/YYYY HH:mm');
  }
}
