const UnitTypeSubgroupForm = () => import('./components/form/unit-type-subgroup-form.component.vue');
const UnitTypeSubgroupView = () => import('./UnitTypeSubgroups.vue');
const UnitTypeSubgroupList = () => import('./components/list/unit-type-subgroup-list.component.vue');

export default {
  path: 'subgrupos-tipo-de-unidades',
  component: UnitTypeSubgroupView,
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'unit-type-subgroup-list',
      component: UnitTypeSubgroupList,
      meta: {
        roles: ['unit_type_subgroup.list'],
        requiresAuth: true,
        breadcrumbs: [{ text: 'Subgrupo cadastrado', href: '' }],
      },
    },
    {
      path: 'cadastro',
      name: 'unit-type-subgroup-create',
      component: UnitTypeSubgroupForm,
      meta: {
        roles: ['unit_type_subgroup.create'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Subgrupo cadastrado', href: 'unit-type-subgroup-list' },
          { text: 'Cadastrar subgrupo', href: '' },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'unit-type-subgroup-edit',
      component: UnitTypeSubgroupForm,
      meta: {
        roles: ['unit_type_subgroup.edit'],
        requiresAuth: true,
        breadcrumbs: [
          { text: 'Subgrupo cadastrado', href: 'unit-type-subgroup-list' },
          { text: 'Editar subgrupo', href: '' },
        ],
      },
    },
  ],
};
