
import { Component, Vue, Watch } from 'vue-property-decorator';
import { BreadcrumsInterface } from './interfaces/breadcrumbs.interface';

@Component
export default class BreadcrumbsmyPropertyComponent extends Vue {
  private breadcrumbs: BreadcrumsInterface[] = [];

  @Watch('$route')
  private getBreadbrumbs() {
    if (!this.$route || !this.$route.meta) return;
    if (this.$route.meta.breadcrumbs) {
      this.breadcrumbs = [];
      this.breadcrumbs = JSON.parse(JSON.stringify(this.$route.meta.breadcrumbs));
      this.breadcrumbs.unshift({ text: 'Home', href: 'home' });
      return;
    }
    this.breadcrumbs = [];
  }

  private goToRoute({ href }: BreadcrumsInterface) {
    if (href) {
      this.$router.push({
        name: href,
      });
    }
  }
}
